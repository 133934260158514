import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { downloadAssetService } from '@/api/requests/assetDownloadRequests.js';
import { saveFileFromBlob } from '@/helpers/util';
import useToastNotifications from './toastNotifications.js';
import { CHECK_ACTIVE_DOWNLOADS, DOWNLOAD_FAILED, ERROR, WARNING } from '@/constants/alerts';
import { DOWNLOAD_FAILED_STATUS, DOWNLOAD_COMPLETED_STATUS, DOWNLOAD_IN_PROGRESS_STATUS } from '@/constants/downloads.js';

export default () => {
    const store = useStore();
    const downloadsList = computed(() => store.getters['activeDownloads/getDownloads'] || []);
    let downloadProgress;
    const { notificationsStack } = useToastNotifications();

    const initDownload = async (body) => {
        // let downloadId;
        try {
            downloadProgress = ref(0);
            // const cancelTokenSource = axios.CancelToken.source();
            // downloadId = 'download' + Date.now();
            await store.dispatch('activeDownloads/addNewDownloadsToList', { body });
            notificationsStack.value.push({
                type: WARNING,
                message: CHECK_ACTIVE_DOWNLOADS
            });
            // const response = await downloadAssetService(setDownloadProgress, url, cancelTokenSource.token);
            // saveFileFromBlob(response.data, url.split('/').slice(-1)[0]);
            // store.dispatch('activeDownloads/updateDownloadStatusInList', {
            //     downloadId,
            //     status: DOWNLOAD_COMPLETED_STATUS
            // });
        } catch (err) {
            console.error(err);
            notificationsStack.value.push({
                type: ERROR,
                message: DOWNLOAD_FAILED
            });
            // store.dispatch('activeDownloads/updateDownloadStatusInList', {
            //     downloadId,
            //     status: DOWNLOAD_FAILED_STATUS
            // });
        }
    };
    const setDownloadProgress = (progress) => {
        downloadProgress.value = progress;
    };
    const removeDownloadFromList = (index) => {
        store.dispatch('activeDownloads/removeDownloadFromList', { itemIndex: index });
    };
    const cancelDownload = (index) => {
        store.dispatch('activeDownloads/cancelDownload', { index });
    };
    const retryDownload = async (index) => {
        const fileDownload = downloadsList.value[index];
        try {
            downloadProgress = ref(0);
            const cancelTokenSource = axios.CancelToken.source();
            store.dispatch('activeDownloads/updateDownloadInList', {
                updatedDownloadItem: {
                    ...fileDownload,
                    progress: downloadProgress,
                    status: DOWNLOAD_IN_PROGRESS_STATUS,
                    cancelTokenSource
                },
                index
            });
            const response = await downloadAssetService(setDownloadProgress, fileDownload.url, cancelTokenSource.token);
            saveFileFromBlob(response.data, fileDownload.url.split('/').slice(-1)[0]);
            store.dispatch('activeDownloads/updateDownloadStatusInList', {
                downloadId: fileDownload.downloadId,
                status: DOWNLOAD_COMPLETED_STATUS
            });
        } catch (err) {
            console.error(err);
            notificationsStack.value.push({
                type: ERROR,
                message: DOWNLOAD_FAILED
            });
            store.dispatch('activeDownloads/updateDownloadStatusInList', {
                downloadId: fileDownload.downloadId,
                status: DOWNLOAD_FAILED_STATUS
            });
        }
    };

    return {
        initDownload,
        removeDownloadFromList,
        cancelDownload,
        retryDownload
    };
};
