import axios from 'axios';

export function downloadAssetService (downloadProgressCB, url, cancelToken) {
    return axios({
        method: 'GET',
        url,
        responseType: 'blob',
        onDownloadProgress (progressEvent) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            downloadProgressCB(progress);
        },
        cancelToken
    });
}
