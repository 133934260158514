<template>
  <base-modal
    modal-title="Save Search"
    @hidden="clearSearchName()"
  >
    <template #modalBody>
      <div class="px-2 py-3">
        <inline-button-input
          v-model="searchName"
          container-class="w-full mb-5"
          placeholder="Enter Search Name"
          :btn-props="{
            text: 'Save',
            variant: 'btn-primary',
            isLoading: savingSearch,
            disabled: !searchName.length
          }"
          :input-props="{
            containerClass: 'h-8 w-full',
            type: 'text',
            maxlength: 99
          }"
          @click="saveSearch()"
        />
        <div
          class="flex items-center"
          :class="searchNameExists ? 'justify-between' : 'justify-end'"
        >
          <div
            v-if="searchNameExists"
            class="text-red-600"
          >
            Search name already exists. Are you sure you want to replace? <br>Click <b>SAVE</b> to continue.
          </div>
          <base-button
            class="mt-2 mb-2"
            variant="btn-gray"
            text="Clear"
            :disabled="savingSearch"
            @click="clearSearchName()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import InlineButtonInput from '@/components/InlineButtonInput.vue';
import { useStore } from 'vuex';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, SAVE_SEARCH_FAILED, SAVE_SEARCH_SUCCESS, SUCCESS } from '@/constants/alerts';

export default {
    name: 'SavedSearchesModal',

    components: {
        InlineButtonInput,
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const searchName = ref('');
        const savingSearch = ref(false);
        const { searchParams } = useStyleGuideAssets();
        const { notificationsStack } = useToastNotifications();
        const savedSearches = computed(() => store.getters['assetsSearch/getSavedSearches']);

        const searchWithInParams = computed(() => store.getters['assetsSearch/getSearchWithInParams']);

        const searchNameExists = computed(() => savedSearches.value.find(e => e.name === searchName.value));

        const store = useStore();
        const saveSearch = async () => {
            try {
                savingSearch.value = true;

                const tmp = {
                    name: searchName.value,
                    searchParams: {
                        ...searchParams.value,
                        ...searchWithInParams.value
                    }
                };
                const curSrch = savedSearches.value.find(e => e.name === tmp.name);
                if (curSrch) tmp.id = curSrch.id;

                emit('hide');
                await store.dispatch('assetsSearch/addNewSearch', tmp);
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: SAVE_SEARCH_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: SAVE_SEARCH_FAILED
                });
            } finally {
                savingSearch.value = false;
                clearSearchName();
            }
        };

        const clearSearchName = () => {
            searchName.value = '';
        };

        return {
            searchName,
            savingSearch,
            saveSearch,
            clearSearchName,
            searchNameExists
        };
    }
};
</script>
