<template>
  <base-modal
    modal-title="Advanced Search"
    modal-title-class="text-black text-md font-semibold"
    header-variant="header-white"
    hide-close-button
  >
    <template #modalBody>
      <div class="p-3">
        <div class="flex justify-between py-4 px-5 border-t border-b border-custom-gray-5 items-center bg-custom-gray-12">
          <span class="w-1/4 mr-5 text-black text-sm">Style Guide Name:</span>
          <div
            class="w-3/4"
          >
            <base-select
              v-model="advanceSearchParams.styleGuideName"
              class="text-sm"
              variant="select-outline"
              text-align="text-center"
              placeholder="Select Style Guide Name"
              :options="styleGuideNameOptions && styleGuideNameOptions.data ? styleGuideNameOptions.data : []"
              defaultOptionText="-- None --"
              showDefaultOption
              @dropdownOpened="fetchStyleGuideNameOptions()"
            />
          </div>
        </div>
        <div class="flex justify-between py-4 px-5 border-b border-custom-gray-5">
          <span class="w-1/4 mr-5 text-black text-sm pt-1">Character Name:</span>
          <custom-multi-inputs
            v-model="advanceSearchParams.characterName"
            class="w-3/4 text-sm"
            :options="characterNameOptions && characterNameOptions.data && characterNameOptions.data.length ? characterNameOptions.data : []"
            :btn-props="{
              disabled: !advanceSearchParams.styleGuideName
            }"
            :input-props="{
              disabled: !advanceSearchParams.styleGuideName,
              type: 'text'
            }"
          />
        </div>
        <div class="flex justify-between py-4 px-5 border-b border-custom-gray-5 bg-custom-gray-12">
          <span class="w-1/4 mr-5 text-black text-sm pt-1">Character Action:</span>
          <div class="w-3/4">
            <base-select
              v-model="advanceSearchParams.characterAction"
              :options="characterActionsOptions?.data?.length > 0 ? characterActionsOptions.data : ['']"
              multi-select
              show-default-option
              placeholder="Select Style Character Action"
              variant="select-outline"
              text-align="text-center"
              class="text-sm"
              :disabled="!advanceSearchParams.styleGuideName"
            />
            <div
              v-if="advanceSearchParams.characterAction && advanceSearchParams.characterAction.length"
              class="text-sm"
            >
              <div class="flex justify-between">
                <span>
                  Selected Choices:
                </span>
              </div>

              <ul class="list-none">
                <li
                  v-for="(cAction, cIndex) in advanceSearchParams.characterAction"
                  :key="'cAction' + cIndex"
                  class="flex items-center"
                >
                  <base-svg
                    class="h-3.5 w-3.5 text-primary-gray inline-block hover:text-gray-900 cursor-pointer mr-1"
                    src="icons/cross.svg"
                    :svg-attributes="{
                      class: 'h-full w-full'
                    }"
                    tag="span"
                    @click="removeSelectedAction(advanceSearchParams.characterAction, cIndex)"
                  />
                  {{ cAction }}
                </li>
              </ul>
            </div>
            <base-button
              v-if="characterActionsOptions?.data?.length > 0"
              variant="btn-border-gray-8 align mt-2 leading-3"
              text="Select All Choices"
              :disabled="!advanceSearchParams.styleGuideName"
              @click="selectAllActionChoices()"
            />
            <base-button
              v-if="advanceSearchParams.characterAction.length > 1"
              variant="btn-border-gray-8 align ml-4 mt-2 leading-3"
              text="Clear All Choices"
              @click="clearSelectedCharacterActions()"
            />
          </div>
        </div>
        <div class="flex justify-between py-4 px-5 border-b border-custom-gray-5 items-center">
          <span class="w-1/4 mr-5 text-black text-sm">Artwork Type:</span>
          <base-select
            v-model="advanceSearchParams.artworkType"
            :options="artworkTypeOptions && artworkTypeOptions.data ? artworkTypeOptions.data : []"
            defaultOptionText="-- None --"
            showDefaultOption
            placeholder="Select Artwork Type"
            variant="select-outline"
            text-align="text-center"
            class="w-3/4 text-sm"
            :disabled="!advanceSearchParams.styleGuideName"
          />
        </div>
        <div class="flex justify-between py-4 px-5 border-b border-custom-gray-5 mb-8 bg-custom-gray-12">
          <span class="w-1/4 mr-5 text-black text-sm">Descriptive Keywords:</span>
          <custom-multi-inputs
            v-model="advanceSearchParams.descriptiveKeywords"
            :options="descriptiveOptions && descriptiveOptions.data && descriptiveOptions.data.length ? descriptiveOptions.data : []"
            class="w-3/4 text-sm"
            :btn-props="{
              disabled: !advanceSearchParams.styleGuideName
            }"
            :input-props="{
              disabled: !advanceSearchParams.styleGuideName,
              type: 'text'
            }"
          />
        </div>
        <div class="font-semibold text-sm px-3 mb-6">
          Note:
          Search gets narrower as more choices are selected. We recommend using a two field combination for search and filter the results later on.
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="text-right border-t p-4">
        <base-button
          variant="btn-green"
          text="Search"
          class="mr-4"
          :disabled="!areAdvanceSearchParamsValid"
          @click="handleSearch()"
        />
        <base-button
          variant="btn-border-gray-5"
          text="Reset"
          class="mr-4"
          @click="handleReset()"
        />
        <base-button
          variant="btn-border-gray-5"
          text="Cancel"
          @click="handleCancel()"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent, computed, ref, reactive, watch, onUpdated } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';

export default {
    name: 'StyleGuideAdvanceSearchModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        CustomMultiInputs: defineAsyncComponent(() => import('@/components/CustomMultiInputs.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        // advance search logic
        const advanceSearchParams = reactive({
            styleGuideName: '',
            artworkType: '',
            descriptiveKeywords: [],
            characterAction: [],
            characterName: []
        });

        const areAdvanceSearchParamsValid = computed(() =>{
          return advanceSearchParams.styleGuideName ||
            advanceSearchParams.artworkType ||
            advanceSearchParams.descriptiveKeywords.filter(e => e).length ||
            advanceSearchParams.characterAction.filter(e => e).length ||
            advanceSearchParams.characterName.filter(e => e).length
        });

        const { searchAssets, searchParams, saveSearchParams, resetAssetsAndSearch, clearSearchParams } = useStyleGuideAssets();

        const handleSearch = async (resetParams = false) => {
            emit('hide');
            clearSearchParams();

            if (resetParams) {
                resetAssetsAndSearch();
            }
            store.dispatch('assets/setSearchPage', 1);

            const tmpParams = {};
            // eslint-disable-next-line no-lone-blocks
            {
                if (advanceSearchParams.styleGuideName) tmpParams.styleGuideName = advanceSearchParams.styleGuideName;
                if (advanceSearchParams.artworkType) tmpParams.artworkType = advanceSearchParams.artworkType;
                if (advanceSearchParams.descriptiveKeywords && advanceSearchParams.descriptiveKeywords[0]) { tmpParams.descriptiveKeywords = advanceSearchParams.descriptiveKeywords.filter(e => e).join('|'); };
                if (advanceSearchParams.characterAction && advanceSearchParams.characterAction[0]) { tmpParams.characterAction = advanceSearchParams.characterAction.filter(e => e).join('|'); };
                if (advanceSearchParams.characterName && advanceSearchParams.characterName[0]) { tmpParams.characterName = advanceSearchParams.characterName.filter(e => e).join('|'); };
            }
            await saveSearchParams(tmpParams, true);

            await searchAssets();
            if (route.name!='StyleGuide') router.push({ name: 'StyleGuide' });
        }

        // cancel logic
        const handleCancel = () => {
            emit('hide')
            // handleReset()
        }

        // reset logic
        const handleReset = () => {
            advanceSearchParams.styleGuideName = ''
            clearAllFiltersExceptStyleGuideName()
        }


        // style guide name filter logic
        const styleGuideNameOptions = ref([])
        const styleGuideNamesLoading = ref(false);
        const fetchStyleGuideNameOptions = async () => {
            try {
                if (styleGuideNamesLoading.value) {
                    console.log(`Still Loading .. fetchStyleGuideNameList`)
                    return;
                }
                styleGuideNamesLoading.value = true;
                let respObj = await store.dispatch('assets/fetchStyleGuideNameList');
                styleGuideNameOptions.value = respObj
            }
            catch (err) { console.error(err) }
            finally {
                styleGuideNamesLoading.value = false;
            }
        };

        // character actions filter logic
        const characterActionsOptions = ref([]);
        const characterActionsLoading = ref(false);
        const fetchCharacterActionsOptions = async () => {
            try {
                if (characterActionsLoading.value) {
                    console.log('Still Loading .. fetchCharacterActionsList');
                    return;
                }
                characterActionsLoading.value = true;
                const respObj = await store.dispatch('assets/fetchCharacterActionsList', {
                    params: {
                        styleGuideName: advanceSearchParams.styleGuideName
                    }
                });
                characterActionsOptions.value = respObj;
            } catch (err) {
                console.error(err);
            } finally {
                characterActionsLoading.value = false;
            }
        };

        // character name filter logic
        const characterNameOptions = ref([])
        const characterNameLoading = ref(false);
        const fetchCharacterNameOptions = async () => {
            try {
                if (characterNameLoading.value) {
                    console.log(`Still Loading .. fetchCharacterNamesList`)
                    return;
                }
                characterNameLoading.value = true;
                let respObj = await store.dispatch('assets/fetchCharacterNamesList', {
                    params: {
                        styleGuideName: advanceSearchParams.styleGuideName
                    }
                });
                characterNameOptions.value = respObj
            }
            catch (err) { console.error(err) }
            finally {
                characterNameLoading.value = false;
            }
        };

        // artwork type filter logic
        const artworkTypeOptions = ref([])
        const artworkTypeLoading = ref(false);
        const fetchArtworkTypeOptions = async () => {
            try {
                if (artworkTypeLoading.value ) {
                    console.log(`Still Loading .. fetchArtworkTypeList`)
                    return;
                }
                artworkTypeLoading.value = true;
                let respObj = await store.dispatch('assets/fetchArtworkTypeList', {
                    params: {
                        styleGuideName: advanceSearchParams.styleGuideName
                    }
                });
                artworkTypeOptions.value = respObj
            }
            catch (err) { console.error(err) }
            finally {
                artworkTypeLoading.value = false;
            }
        };

        // descriptive options filter logic
        const descriptiveOptions = ref([])
        const descriptiveNameLoading = ref(false);
        const fetchDescriptiveOptions = async () => {
            try {
                if (descriptiveNameLoading.value) {
                    console.log(`Still Loading .. fetchDescriptiveNamesList`)
                    return;
                }
                descriptiveNameLoading.value = true;
                let respObj = await store.dispatch('assets/fetchDescriptiveNamesList', {
                    params: {
                        styleGuideName: advanceSearchParams.styleGuideName
                    }
                });
                descriptiveOptions.value = respObj
            }
            catch (err) { console.error(err) }
            finally {
                descriptiveNameLoading.value = false;
            }
        };


        watch(
            () => advanceSearchParams.styleGuideName,
            () => {
                clearAllFiltersExceptStyleGuideName()
                if (advanceSearchParams.styleGuideName !== '') {

                    characterActionsLoading.value = false
                    fetchCharacterActionsOptions()

                    characterNameLoading.value = false
                    fetchCharacterNameOptions()

                    artworkTypeLoading.value = false
                    fetchArtworkTypeOptions()

                    descriptiveNameLoading.value = false
                    fetchDescriptiveOptions()

                }
            }
        );

        const clearAllFiltersExceptStyleGuideName = () => {
            clearSearchParams();

            clearSelectedCharacterActions();

            advanceSearchParams.artworkType = '';
            advanceSearchParams.descriptiveKeywords = [''];
            advanceSearchParams.characterName = [''];
            advanceSearchParams.characterAction = [];
        };

        const selectAllActionChoices = () => advanceSearchParams.characterAction = characterActionsOptions.value.data;
        const clearSelectedCharacterActions = () => advanceSearchParams.characterAction = [];
        const removeSelectedAction = (field, index) => {
            field.splice(index, 1);
            fetchCharacterActionsOptions();
        };

        onUpdated(() => {
            fetchStyleGuideNameOptions();
            if (!advanceSearchParams.styleGuideName) clearSearchParams();
        });

        return {
            styleGuideNameOptions,
            fetchStyleGuideNameOptions,

            characterActionsOptions,
            fetchCharacterActionsOptions,

            characterNameOptions,
            fetchCharacterNameOptions,

            artworkTypeOptions,
            fetchArtworkTypeOptions,

            descriptiveOptions,
            fetchDescriptiveOptions,

            advanceSearchParams,

            removeSelectedAction,
            clearSelectedCharacterActions,
            selectAllActionChoices,

            areAdvanceSearchParamsValid,

            handleSearch,
            handleReset,
            handleCancel,
        };
    }
};
</script>
