<template>
  <base-modal
    modal-title="Active Downloads"
  >
    <template #modalBody>
      <div
        v-if="loader"
        class="text-center w-full py-10"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <div
        v-else
        class="px-2 py-3"
      >
        <div class="mb-4">
          <template v-if="fetchedActiveDowloadList?.length">
            <div
              v-for="(download, dIndex) in fetchedActiveDowloadList"
              :key="'download' + dIndex"
              class="flex text-sm w-full p-2 mb-2 bg-custom-gray-5 h-12"
            >
              <div class="w-3/5 my-auto">
                Name: <span class="font-semibold">{{ download.jobName }}</span>
              </div>
              <div class="w-1/5 my-auto uppercase text-center font-semibold">
                <span :class="download.jobStatus === 'Complete' ? 'text-green-700' : 'text-yellow-700'">{{ download.jobStatus }}</span>
              </div>
              <div class="flex w-1/5 my-auto justify-end">
                <base-button
                  v-if="download.jobStatus === 'Complete'"
                  class="h-5 flex items-center"
                  variant="btn-primary"
                  text="Download"
                  :is-loading="isLoading"
                  @click="downloadCompletedJob(download.jobResults.downloadUrl)"
                />
                <base-button
                  v-else
                  class="h-5 flex items-center"
                  variant="btn-primary"
                  text="Refresh"
                  :is-loading="isLoading"
                  @click="refreshJobDetails(download.jobId)"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center mx-auto py-8">No active downloads available.</div>
          </template>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { useStore } from 'vuex';
import useDownloadAsset from '@/hooks/downloadAsset.js';
import { computed, defineAsyncComponent, ref } from 'vue';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { DOWNLOAD_CANCELLED_STATUS, DOWNLOAD_FAILED_STATUS } from '@/constants/downloads';
import { ATTACHEMENT_DOWNLOADED_SUCCESSFULLY, ATTACHEMENT_DOWNLOAD_FAILED, ERROR, SUCCESS } from '@/constants/alerts';

export default {
    name: 'ActiveDownloadsPopup',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },
    props: {
        loader: {
            type: Boolean,
            default: undefined
        }
    },

    setup (props) {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const { removeDownloadFromList, cancelDownload } = useDownloadAsset();

        const downloadsList = computed(() => store.getters['activeDownloads/getDownloads'] || []);
        const removeDownload = (index) => {
            removeDownloadFromList(index);
        };

        const fetchedActiveDowloadList = computed(() => store.getters['activeDownloads/getActiveDownloadList'] || []);
        const isLoading = ref(false);

        const refreshJobDetails = async (id) => {
            try {
                isLoading.value = true;
                await store.dispatch('activeDownloads/fetchActiveDowloadJobById', id);
            } catch (err) {
                console.error(err);
            } finally {
                isLoading.value = false;
            }
        };

        const isDownloadingUrlLoading = ref(false);
        const downloadCompletedJob = (downloadUrl) => {
            try {
                isDownloadingUrlLoading.value = true;

                if (!downloadUrl) throw new Error('Download URL is not available.')
                const windowOpened = window.open(downloadUrl, '_blank');
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ATTACHEMENT_DOWNLOADED_SUCCESSFULLY
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ATTACHEMENT_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                isDownloadingUrlLoading.value = false;
            }
        };

        return {
            DOWNLOAD_CANCELLED_STATUS,
            DOWNLOAD_FAILED_STATUS,
            downloadsList,
            removeDownload,
            cancelDownload,
            downloadCompletedJob,
            refreshJobDetails,
            isLoading,
            fetchedActiveDowloadList
        };
    }
};
</script>
